import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import Boxes from '../components/share/boxes'
import { brandLogoMedia } from '../components/share/mediaImport'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import Quote from '../components/share/quote'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const items = [
  {
    iconURL: brandLogoMedia.brandBellePropertyIconSvg,
  },
  {
    iconURL: brandLogoMedia.brandUpstateIconSvg,
  },
  {
    iconURL: brandLogoMedia.brandMcConnelBournIconSvg,
  },
  {
    iconURL: brandLogoMedia.brandAvnuIconSvg,
  },
]

const query = graphql`
  query {
    header: file(relativePath: { eq: "realStudios/Header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monitor: file(relativePath: { eq: "realStudios/monitor.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    suit: file(relativePath: { eq: "realStudios/suit.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    drone: file(relativePath: { eq: "realStudios/drone.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    grantCordone: file(
      relativePath: { eq: "brandAgentIcon/grant_cordone.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photoCollage: file(relativePath: { eq: "realStudios/photocollage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    aerial: file(relativePath: { eq: "realStudios/aerial.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    camera: file(relativePath: { eq: "realStudios/camera.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    australia: file(relativePath: { eq: "realStudios/australia.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mediaReviewer: file(relativePath: { eq: "realStudios/mediareviewer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class RealstudiosPage extends Component {
  _scrollToSection = title => {
    this[title].scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '600px' }}
                opacity="0.5"
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <HeaderTitle>
                    Prestigious property marketing, for any budget.
                  </HeaderTitle>

                  <HeaderBody>
                    Embracing the importance of{' '}
                    <strong>emotive storytelling</strong>, and its power to help
                    sell <strong>real estate more effectively</strong> the
                    RealStudios team works across{' '}
                    <strong>video, photography and social content</strong> to
                    strengthen the brand presence of hundreds of agents.
                  </HeaderBody>

                  <HeaderBody>
                    Shooting for professional agencies, just like yours at
                    affordable prices is now a reality.
                  </HeaderBody>
                  <HeaderLink onClick={() => this._scrollToSection('video')}>
                    Contact us now...
                    <FontAwesomeIcon
                      icon={['fal', 'arrow-right']}
                      style={{
                        width: '14px',
                        height: '14px',
                        marginBottom: 0,
                        marginLeft: '10px',
                      }}
                    />
                  </HeaderLink>
                </ContentWrapper>
              </ImageBackground>
              <DisplayWrapper>
                <Boxes
                  name="brands"
                  items={items}
                  rowItems="4"
                  boxMargin="5px"
                  adjustHeight="100px"
                  iconSize="contain"
                  iconLength="90%"
                  background="#ffffff"
                  iconColor="brightness(0) saturate(100%) invert(25%) sepia(0%) saturate(100%) hue-rotate(86deg) brightness(101%) contrast(100%)"
                  hover={false}
                />
              </DisplayWrapper>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Communicate <strong> visually</strong>, and incite strong{' '}
                  <strong>emotion from buyers</strong>, delivering exceptional
                  results <strong>for your sellers. </strong>
                </BannerH4>
                <BannerH6>
                  Start distributing emotional, visually impactful content for
                  yourself and your properties with our full suite of marketing
                  services. Available exclusively to the real eastate industry.
                  Our focus on property, and agents makes us the best choice of
                  production team for any real estate business project.
                </BannerH6>
              </ContentWrapper>
              <ContentWrapper direction="row" style={{ alignItems: 'center' }}>
                <FeatureBox
                  image={data.monitor.childImageSharp.fluid}
                  contentStyle={{ height: '110px' }}
                  title="Property Video & Photography"
                  subTitle="Sell listings faster, for more money, with emotive video content."
                  content="The support we get from the technology side of our business, alongside the best equipment and people lea to the best results."
                  detailLinks={[
                    {
                      text: 'See Video Example',
                      link: '271622104',
                      lightbox: true,
                    },
                  ]}
                />
                <FeatureBox
                  image={data.suit.childImageSharp.fluid}
                  contentStyle={{ height: '110px' }}
                  title="Agent Promo Content"
                  subTitle="Attract new business, educate your  market and build a database."
                  content="We know marketing, and can deliver agent and agency promotional content from concept to final delivery and implementation."
                  detailLinks={[
                    {
                      text: 'See Suburb Wrap-up',
                      link: '271622104',
                      lightbox: true,
                    },
                    {
                      text: 'See Video Series',
                      link: 'https://vimeo.com/showcase/5970619',
                      lightbox: false,
                    },
                  ]}
                />

                <FeatureBox
                  image={data.drone.childImageSharp.fluid}
                  contentStyle={{ height: '110px' }}
                  title="Drone Video and Photography"
                  subTitle="Get angles that make an impact and highlight."
                  content="Professionally licensed and insured pilots operating the best technology possible with the best minds behind the controls."
                  detailLinks={[
                    {
                      text: 'See Video with Drone Example',
                      link: '271620709',
                      lightbox: true,
                    },
                  ]}
                />
              </ContentWrapper>
              <ContentWrapper>
                <Quote
                  agentName="Grant Cordone"
                  agentImage={data.grantCordone.childImageSharp.fluid}
                  agencyName="grantCordone"
                  imgStyle={{ width: '60px' }}
                  title="World #1 Sales Trainer"
                  desc={`“What if the only thing standing in the way of your greatness was that you just had to go after everything obsessively, persistently, and as though your life depended on it?`}
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  videoId="271620709"
                  linkText="See our Video Examples..."
                  linkIcon={true}
                  content={
                    <Fragment>
                      <Paragraph>
                        The real estate industry is{' '}
                        <strong> really crowded</strong>, there's no denying it.{' '}
                        <strong>
                          {' '}
                          How can you stay ahead? How can you stand out?{' '}
                        </strong>{' '}
                        The RealStudios teams deliver consistent, high quality
                        results time and time again.
                      </Paragraph>
                      <Paragraph>
                        73% of homeowners are more likely to list with an agent
                        who offers video in their marketing.
                      </Paragraph>
                      <Paragraph>
                        We offer video packages for every budget, we can make
                        the most effective marketing packages at the lowest
                        price points, and offer extended packages for properties
                        that require a bit of excess to really stand out.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.photoCollage.childImageSharp.fluid}
                  linkText="View Full Album..."
                  linkIcon={true}
                  reverse={true}
                  contentImage={data.camera.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        Better quality photography equals{' '}
                        <strong> more views on portals</strong>, and{' '}
                        <strong> more impressions on social media</strong>, and
                        more views and impressions means more views to opens.
                      </Paragraph>
                      <Paragraph>
                        Get better sales results, with the best photography in
                        the industry.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.aerial.childImageSharp.fluid}
                  linkText="View Aerial Examples..."
                  linkIcon={true}
                  content={
                    <Fragment>
                      <Paragraph>
                        No longer just the realm of the{' '}
                        <strong>highest ticket pretige property</strong> and{' '}
                        <strong>expensive helicopter shoots</strong>, today’s
                        market expects aerial video and photography to help
                        outline where about their dream property sits. Thanks to
                        the technology advancements in the last 10 years, it’s
                        now possible to offer your clients aerial footage within
                        almost any budget.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <SplitRow>
                <SplitRowImg fluid={data.mediaReviewer.childImageSharp.fluid} />
                <SplitRowText>
                  <Fragment>
                    <Icon icon={['fal', 'mobile-android']} margin="15%" />
                    <Paragraph color="#FFFFFF">
                      These days, there’s more to getting a property covered
                      than just taking the photos, the time and effort it takes
                      to partake in proofing and bringing your content to the
                      places you need it most has to be considered too.
                    </Paragraph>
                    <Paragraph color="#FFFFFF">
                      We use the industries best software to help make marketing
                      easier than ever.
                    </Paragraph>
                  </Fragment>
                </SplitRowText>
              </SplitRow>
              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  title="A supportive national network of highly skilled photographers."
                  image={data.australia.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          We offer our real estate services to the Northern
                          Beaches and greater Sydney area exclusively.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        However if you’re outside that area, we have a network
                        of experienced reliable contractors across the country.
                        If you’re looking for national brand consistency in
                        large groups, or simply looking to take advantage of the
                        easy to use deliver tools realhub’s photography and
                        video packages include, we can recommend photographers
                        to suit your style and budget Australia wide.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <Paragraph>
                  <strong>Our Services:</strong>
                </Paragraph>
                <ServicesList>
                  <ServicesColumn>
                    <p>Property Video</p>
                    <p>Agent Marketing Promotion</p>
                    <p>Agency and Office Content</p>
                    <p>Location & Suburb Videos</p>
                    <p>Lifestyle Videos</p>
                    <p>Market Reports</p>
                  </ServicesColumn>
                  <ServicesColumn>
                    <p>Property Photographer</p>
                    <p>Agency Photography</p>
                    <p>Location & Suburb Photography</p>
                    <p>Lifestyle Photography</p>
                  </ServicesColumn>
                  <ServicesColumn>
                    <p>Property Floorplans</p>
                    <p>Aerial Visualisations</p>
                    <p>3D Renderings</p>
                  </ServicesColumn>
                </ServicesList>
              </ContentWrapper>
              <ContentWrapper contentRef={el => (this.video = el)}>
                <SpeakToTeam />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default RealstudiosPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const HeaderLink = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  cursor: pointer;

  ${customMedia.greaterThan('desktop')`
    font-size: 18px;
    margin-bottom: 10px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
  color: ${props => props.color || 'initial'};
`

const DisplayWrapper = styled.div`
  display: none;

  ${customMedia.greaterThan('mobile')`
    position: relative;
    display: flex;
    width: 768px;
    margin: 90px auto;
    align-items: flex-start;
    flex-wrap: nowrap;
  `};
`

const SplitRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  background-color: #03070a;

  ${customMedia.greaterThan('mobile')`
    flex-direction: row;
    margin: 0;
  `};
`

const SplitRowImg = styled(Img)`
  flex: 1;
  height: 500px;
`

const SplitRowText = styled.div`
  flex: 1;
  margin: 30px 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    width: 70%;
  }

  ${customMedia.greaterThan('mobile')`
    margin-bottom: 0;
    align-items: flex-start;
  `};
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 25px;
  margin-bottom: 10px;
  align-self: flex-start;
  margin-left: ${props => props.margin || '0'};

  ${customMedia.greaterThan('mobile')`
    margin-left: 0;
  `};
`

const ServicesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  font-weight: 200;

  ${customMedia.greaterThan('mobile')`
    flex-direction: row;
    font-size: 14px;
  `};
`

const ServicesColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;

  ${customMedia.greaterThan('mobile')`
    width: calc(100% / 3);
    margin-left: 0;
  `};
`
